<template>
  <div class="mainwhite">
    <div class="mainwhite-tiop">
      <el-form
          :inline="true"
          ref="formData"
          :model="formData"
          class="demo-form-inline"
      >
        <el-form-item style="float: left; margin-left: 0px; color: #bb0000">
        </el-form-item>
        <el-form-item >
          <el-select style="width: 100px"
              v-model="formData.pvType"
              placeholder="业绩类型" @change="changePvType"
          >
            <el-option :label="item.name" :value="item.id" v-for="(item, index) in pvTypes" />
          </el-select>
        </el-form-item>
        <el-form-item >
          <el-select style="width: 650px"
              v-model="formData.goods_id"
              placeholder="选择商品" multiple
          >
            <el-option :label="item.name" :value="item.goods_id" v-for="(item, index) in settleGoodsList" />
          </el-select>
        </el-form-item>
<!--        <el-form-item>-->
<!--          <el-select-->
<!--              style="width: 120px"-->
<!--              v-model="formData.status"-->
<!--              placeholder="奖金状态"-->
<!--          >-->
<!--            <el-option label="全部" value="0">全部</el-option>-->
<!--            <el-option label="已结算" value="200">已结算</el-option>-->
<!--            <el-option label="待结算" value="1">待结算</el-option>-->
<!--            <el-option label="已失效" value="-1">已失效</el-option>-->
<!--          </el-select>-->
<!--        </el-form-item>-->
        <el-form-item>
          <el-input
              placeholder="请输入内容"
              v-model="formData.keywords"
              class="input-with-select"
          >
            <el-select
                style="width: 120px"
                v-model="formData.searchField"
                slot="prepend"
                placeholder="请选择"
            >
<!--              <el-option label="手机号" value="2">手机号</el-option>-->
              <el-option label="会员ID" value="1">会员ID</el-option>
              <el-option label="区域名称" value="5">区域名称</el-option>
<!--              <el-option label="昵称" value="3">昵称</el-option>-->
<!--              <el-option label="姓名" value="4">姓名</el-option>-->
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch('formData')">查询</el-button>
          <el-button type="primary" @click="addArea">添加代理</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
        v-loading="loading"
        :data="tableData"
        border
        stripe
        style="width: 100%; margin-top: 20px; margin-bottom: 20px; max-height: 700px; overflow: auto;"
    >
      <el-table-column prop="id" label="#ID" width="100"> </el-table-column>
      <el-table-column prop="uid" width="100" label="会员ID">
      </el-table-column>
      <el-table-column prop="userInfo.phone" width="120" label="手机" >
      </el-table-column>
      <el-table-column prop="userInfo.nickname" width="150" label="昵称" >
      </el-table-column>
      <el-table-column prop="province_str" width="150" label="省" >
      </el-table-column>
      <el-table-column prop="area_str" width="150" label="市" >
      </el-table-column>
      <el-table-column prop="county_str" width="150" label="区(县)" >
      </el-table-column>
      <el-table-column prop="goodsInfo.store_name" label="包含商品" >
      </el-table-column>
      <el-table-column prop="created_at" label="更新时间" >
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          width="100">
        <template slot-scope="scope">
          <el-button @click="delArea(scope.row.id)" type="text" size="small">删除区域</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination
          style="margin-top: 1rem"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="formData.page"
          :page-sizes="pageSizs"
          :page-size="formData.pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="formData.total"
      >
      </el-pagination>
    </div>


    <!-- Form -->

    <el-dialog
        title="添加代理"
        :visible.sync="dialogFormVisible"
        v-loading="loading"
    >
      <el-form :model="addFormData"  ref="formData">
        <el-form-item
            label="会员ID"
            :label-width="formLabelWidth"
            prop="uid"
            >
          <el-input v-model="addFormData.uid" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
            label="省代理区域"
            :label-width="formLabelWidth"
            prop="province_str">
          <el-autocomplete
              v-model="addFormData.province_str"
              :fetch-suggestions="(queryString, cb) => {querySearchAsync(queryString, cb, 1)}"
              placeholder="请输入内容"
              @select="handleSelect"
          ></el-autocomplete>
        </el-form-item>
        <el-form-item
            label="市代理区域"
            :label-width="formLabelWidth"
            prop="area_str">
          <el-autocomplete
              v-model="addFormData.area_str"
              :fetch-suggestions="(queryString, cb) => {querySearchAsync(queryString, cb, 2)}"
              placeholder="请输入内容"
              @select="handleSelect"
          ></el-autocomplete>
        </el-form-item>
        <el-form-item
            label="区(县)代理区域"
            :label-width="formLabelWidth"
            prop="county_str">
          <el-autocomplete
              v-model="addFormData.county_str"
              :fetch-suggestions="(queryString, cb) => {querySearchAsync(queryString, cb, 3)}"
              placeholder="请输入内容"
              @select="handleSelect"
          ></el-autocomplete>
        </el-form-item>
        <el-form-item label="业绩类型" :label-width="formLabelWidth">
          <el-select
                     v-model="addFormData.pv_type"
                     placeholder="业绩类型" @change="changeAddPvType"
          >
            <el-option :label="item.name" :value="item.id" v-for="(item, index) in pvTypes" />
          </el-select>
        </el-form-item>
        <el-form-item
            label="选择商品"
            :label-width="formLabelWidth"
            prop="goods_ids"
            >
          <el-select style="width: 650px"
                     v-model="addFormData.goods_ids"
                     placeholder="选择商品" multiple
          >
            <el-option :label="item.name" :value="item.goods_id" v-for="(item, index) in addFormData.settleGoodsList" />
          </el-select>
        </el-form-item>
<!--        -->
<!--        <el-form-item-->
<!--            label="是否开启"-->
<!--            :label-width="formLabelWidth"-->
<!--            style="text-align: left" prop="status"-->
<!--        >-->
<!--          <el-switch-->
<!--              v-model="formData.status"-->
<!--              active-color="#13ce66"-->
<!--              inactive-color="#ff4949"-->
<!--          >-->
<!--          </el-switch>-->
<!--        </el-form-item>-->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="addSettleArea">确 定</el-button>
      </div>
    </el-dialog>


  </div>
</template>

<script>
export default {
  name: "SetBillingArea",
  components: {},
  data() {
    return {
      formData: {
        page: 1,
        pagesize: 10,
        total: 0,
        keywords: "",
        searchField: "1",
        isExport:0,
        status: '',
        bonus_type: '',
        goods_id: '0',
        pvType: '0'
      },
      tableData: [],
      loading: false,
      formLabelWidth: "120px",
      bonusTypeList: [],
      bonus_statue200: 0,
      bonus_statuef1: 0,
      bonus_statue0: 0,

      settleGoodsList: [],
      pvTypes: [],
      dialogFormVisible: false,
      addFormData: {
        uid: '',
        goods_ids: [],
        area_str: '',
        province_str: '',
        county_str: '',
        pv_type: '',
        settleGoodsList: []
      },
    };
  },
  methods: {
    getList(page = 0) {
      this.tableData = [];
      if (page > 0) {
        this.formData.page = page;
      }
      this.loading = true;
      this.myfun.request("adminSettleGetSettleArea", this.formData)
          .then((result) => {
            if (result.code == "1") {
              this.tableData = result.data.list;
            }
            this.formData.total = parseInt(result.data.total);
            // this.settleGoodsList = [];
            // result.data.settleGoodsList.forEach((item, index) => {
            //   this.settleGoodsList.push({
            //     goods_id: item.goods_id,
            //     name: item.name
            //   })
            // })
            this.pvTypes = result.data.pvTypes;
            if(this.pvTypes.length > 0 && this.formData.pvType == 0){
              this.formData.pvType = this.pvTypes[0].id;
              this.settleGoodsList = this.pvTypes[0].goods;
              this.getList(1)
            }
            this.loading = false;
          });
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.formData.pagesize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.formData.page = val;
      this.getList();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleClose(tag) {
      if (this.multipleSelection.length > 1) {
        this.$refs.dataTable.toggleRowSelection(tag, false);
      } else {
        this.$message.warning("请至少要保留一个操作项");
      }
    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //验证通过执行请求
          this.loading = true;
          let apiName =
              this.editNetformData.type === 1
                  ? "adminMemberEditMemberRecommend.api"
                  : "adminMemberEditMemberParent.api";
          this.myfun.request(apiName, this.editNetformData)
              .then((result) => {
                if (result.code == "1") {
                  this.getList();
                  this.editNetdialogFormVisible = false;
                }
                this.loading = false;
              });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    onSearch() {
      this.getList(1);
    },

    changePvType(val) {
      this.formData.goods_id = ''
      this.pvTypes.forEach((item, index) => {
        if(item.id == val){
          this.settleGoodsList = item.goods;
          this.getList(1)
        }
      })
    },
    changeAddPvType(val) {
      this.addFormData.goods_ids = [];
      this.pvTypes.forEach((item, index) => {
        if(item.id == val){
          this.addFormData.settleGoodsList = item.goods;
        }
      })
    },

    addArea(){
      this.addFormData.pv_type = this.pvTypes[0].id;
      this.addFormData.settleGoodsList = this.pvTypes[0].goods;
      this.dialogFormVisible = true;
    },

    querySearchAsync(queryString, cb, type) {
      console.log(type)
      this.myfun
          .request('commonPubGetOrderAddressAreaList.api', {keywords: queryString, type: type}, "post")
          .then((result) => {
            if (result.code == "1") {
              cb(result.data);
            }
          });
    },
    addSettleArea(){
      this.myfun.request('adminSettleAddSettleArea', this.addFormData, 'post').then((result) => {
        if (result.code == "1") {
          this.$message({
            message: '添加成功！',
            type: "success",
          });
          this.dialogFormVisible = false;
          this.getList(1);
          this.addFormData.uid = '';
          this.addFormData.goods_ids = [];
          this.addFormData.area_str = '';
          this.addFormData.province_str = '';
          this.addFormData.pv_type = '';
        }else{
          this.$message({
            message: result.msg,
            type: "warning"
          })
        }
      })
    },

    delArea(id){
      this.myfun.request('adminSettleDelSettleArea', {id: id}, 'post').then((result) => {
        if (result.code == "1") {
          this.$message({
            message: '删除成功！',
            type: "success",
          });
          this.getList(1);
        }
      })
    }
  },
  created() {
    this.getList(1);
  },
  mounted() {},
  computed: {
    pageSizs() {
      return this.$store.state.pageSizes;
    },
  },
};
</script>

<style>
.huiyuanxinxi .el-form-item {
  margin: 0 0 15px 0;
}
.switshsdf {
  display: flex;
  flex-direction: column;
}
.mainwhite {
  background-color: #fff;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
}
.mainwhite-tiop {
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #f5f5f5;
  justify-content: flex-end;
}

.el-form-item {
  margin-bottom: 0;
}
</style>
