<template>
  <div class="mainwhite">
    <div class="mainwhite-tiop">
      <el-form
        :inline="true"
        ref="formData"
        :model="formData"
        class="demo-form-inline"
        style="text-align: left"
      >
        <el-form-item>
          <el-input
            placeholder="请输入内容"
            v-model="formData.keywords"
            class="input-with-select"
          >
            <el-select
              style="width: 130px"
              v-model="formData.searchField"
              slot="prepend"
              placeholder="请选择"
            >
              <el-option label="周期编号" value="1">周期编号</el-option>
              <!--            <el-option label="会员编号" value="2">会员编号</el-option>-->
              <!--            <el-option label="手机号" value="3">手机号</el-option>-->
              <!--              <el-option label="身份证号" value="3">身份证号</el-option>-->
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch('formData')"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>
<!--    <el-form v-if="myfun.checkAuthRule('adminSettleBatchAwards')">-->
<!--      <el-button-->
<!--        @click="statusManage(1)"-->
<!--        style="width: 100px; margin-top: 20px; float: left"-->
<!--        :disabled="multipleSelection.length < 1"-->
<!--      >-->
<!--        奖金发放-->
<!--      </el-button>-->
<!--    </el-form>-->
    <el-table
      v-if="showTable"
      v-loading="loading"
      :data="tableData"
      row-key="id"
      border
      lazy
      stripe
      :load="getHistorySettleList"
      style="width: 100%; margin-top: 20px"
      @selection-change="handleSelectionChange"
    >
<!--      <el-table-column-->
<!--        type="selection"-->
<!--        align="center"-->
<!--        header-align="center"-->
<!--        width="40"-->
<!--      >-->
<!--      </el-table-column>-->
      <el-table-column
          align="center"
          header-align="center"
          prop="id"
          label="#ID"
      ></el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        prop="name"
        width="140"
        label="周期编号"
      ></el-table-column>
      <el-table-column
          prop="name"
          label="类型"
      >
        <template slot-scope="scope">

          <span v-if="scope.row.type == '1'" v-html="
          '日结'"></span>
          <span v-else-if="scope.row.type === '2'" v-html="
          '月结'"></span>
          <span v-else-if="scope.row.type === '3'" v-html="
          '分红日结'"></span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        prop="total_order"
        label="总订单数"
      ></el-table-column>
      <el-table-column
          prop="payable_bonus"
          label="应发总奖金"
      >
      </el-table-column>
      <el-table-column
          prop="paid_bonus"
          label="实发总奖金"
      >
        <template slot-scope="scope" >
          <template v-if="(scope.row.type == '1' || scope.row.type === '3') && scope.row.status_str !== '已完成'">
            <el-input v-model="scope.row.paid_bonus" @blur="changeSettleStatus(scope.row.paid_bonus, scope.row.id, 'paid_bonus')"></el-input>
          </template>
          <template v-else>
            {{scope.row.paid_bonus}}
          </template>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        prop="start_time"
        label="起始时间" width="100"
      >
      </el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        prop="end_time"
        width="100"
        label="结束时间"
      >
        <template slot-scope="scope">
          <span>
            {{ scope.row.end_time | timeStrSubOneSecond }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        prop="status_str"
        label="状态" width="200"
      >
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            effect="dark"
            v-if="scope.row.status_str === '已归档'"
            content="已归档结算数据保留3个月"
            placement="top-start"
          >
            <span>{{ scope.row.status_str }}</span>
          </el-tooltip>
          <span v-else>{{ scope.row.status_str }}</span>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        label="操作"
        width="100"
        align="center"
        header-align="center"
      >
        <template slot-scope="scope">
          <el-popconfirm
            v-if="
              myfun.checkAuthRule('adminSettleResetSettle') &&
              scope.row.status === '90'
            "
            confirm-button-text="好的"
            cancel-button-text="不用了"
            icon="el-icon-info"
            icon-color="red"
            title="确定要重新结算吗？"
            @confirm="resetSettle(scope.row)"
          >
            <el-button
              style="margin: 2px 0"
              slot="reference"
              type="primary"
              plain
              size="small"
              >重新结算</el-button
            >
          </el-popconfirm>

          <el-button
            style="margin: 2px 0"
            @click="
              myfun.myRouterAutoPush('/settle/settle-pv-list', {
                id: scope.row.id,
              })
            "
            type="warning"
            plain
            size="small"
            v-if="myfun.checkAuthRule('adminMemberEditMemberRecommend')"
            >业绩列表</el-button
          >

          <el-button
            style="margin: 2px 0"
            @click="
              myfun.myRouterAutoPush('/settle/settle-bonus-list', {
                id: scope.row.id,
              })
            "
            type="success"
            plain
            size="small"
            >奖金明细</el-button
          >

          <el-popconfirm
            v-if="
              myfun.checkAuthRule('adminSettleSettleAudit') &&
              scope.row.status === '90'
            "
            confirm-button-text="好的"
            cancel-button-text="不用了"
            icon="el-icon-info"
            icon-color="red"
            title="一旦核实，本期结算结果将归档，不再支持重新结算，确定要进行该操作吗？"
            @confirm="settleAudit(scope.row)"
          >
            <el-button
              style="margin: 2px 0"
              slot="reference"
              type="danger"
              size="small"
              >结算核实</el-button
            >
          </el-popconfirm>
          <el-popconfirm
            v-if="
              myfun.checkAuthRule('adminSettleSettleGiveBonus') &&
              scope.row.status >= 100 &&
              scope.row.status <= 200 &&
              scope.row.is_give_bonus === '0'
            "
            confirm-button-text="好的"
            cancel-button-text="不用了"
            icon="el-icon-info"
            icon-color="red"
            title="确定要发放奖金吗？"
            @confirm="settleGiveBonus(scope.row)"
          >
            <el-button
              style="margin: 2px 0"
              slot="reference"
              type="danger"
              size="small" v-if="scope.row.month_period === '4'"
              >奖金发放</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination
        style="margin-top: 1rem"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="formData.page"
        :page-sizes="pageSizs"
        :page-size="formData.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="formData.total"
      >
      </el-pagination>
    </div>

    <el-drawer
      :title="operateTitle"
      :visible.sync="showLog"
      direction="ltr"
      size="100%"
    >
      <el-table
        v-loading="loading"
        :data="bonusListData"
        stripe
        style="width: 100%"
        max-height="600"
      >
        <el-table-column prop="id" label="#ID" width="50"> </el-table-column>
        <el-table-column prop="bonusType" label="奖金类型" width="100">
        </el-table-column>
        <el-table-column prop="payable_bonus" label="应发" width="100">
        </el-table-column>
        <el-table-column prop="paid_bonus" label="实发" width="100">
        </el-table-column>
        <el-table-column prop="freezeStatusStr" label="保留状态" width="100">
        </el-table-column>
        <el-table-column prop="restrictedAwardsStr" label="冻结状态" width="100">
        </el-table-column>
        <el-table-column prop="created_at" label="结算时间" width="200">
        </el-table-column>
      </el-table>
    </el-drawer>


    <!-- 批量发奖 -->
    <el-dialog
      title="批量发奖"
      :model="bitchFormData"
      :visible.sync="batch_manage"
      v-loading="loading"
    >
      <el-table
        :data="multipleSelection"
        row-key="id"
        border
        stripe
        :load="getHistorySettleList"
        style="width: 100%; margin-top: 20px"
      >
        <el-table-column
          align="center"
          header-align="center"
          prop="id"
          label="#ID"
        ></el-table-column>
        <el-table-column
          align="center"
          header-align="center"
          prop="name"
          width="140"
          label="周期编号"
        ></el-table-column>
        <el-table-column
          align="center"
          header-align="center"
          prop="new_member_num"
          label="新增会员"
        ></el-table-column>
        <el-table-column
          align="center"
          header-align="center"
          prop="total_member"
          label="总会员数"
        ></el-table-column>
        <el-table-column
          align="center"
          header-align="center"
          prop="total_order"
          label="总订单数"
        ></el-table-column>
        <el-table-column
          align="center"
          header-align="center"
          prop="total_order_amount"
          label="总金额"
        ></el-table-column>
        <el-table-column
          align="center"
          header-align="center"
          prop="total_pv"
          label="总PV"
        >
        </el-table-column>
        <el-table-column
          align="center"
          header-align="center"
          prop="payable_bonus"
          label="总奖金"
        >
        </el-table-column>
        <el-table-column
          align="center"
          header-align="center"
          prop="bonusOrderAmountRate"
          label="金额拨比"
        >
        </el-table-column>
        <el-table-column
          align="center"
          header-align="center"
          prop="bonusPvRate"
          label="PV拨比"
        >
        </el-table-column>
        <el-table-column
          align="center"
          header-align="center"
          prop="start_time"
          label="起始时间" width="100"
        >
        </el-table-column>
        <el-table-column
          align="center"
          header-align="center"
          prop="end_time"
          width="100"
          label="结束时间"
        >
          <template slot-scope="scope">
          <span>
            {{ scope.row.end_time | timeStrSubOneSecond }}
          </span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          header-align="center"
          prop="status_str"
          label="状态" width="200"
        >
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              v-if="scope.row.status_str === '已归档'"
              content="已归档结算数据保留3个月"
              placement="top-start"
            >
              <span>{{ scope.row.status_str }}</span>
            </el-tooltip>
            <span v-else>{{ scope.row.status_str }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="batch_manage = false">取 消</el-button>
        <el-button type="primary" @click="doBatchManage()"> 确 定 </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "SettleList",
  components: {},
  data() {
    return {
      test: true,
      formData: {
        page: 1,
        pagesize: 10,
        total: 0,
        keywords: "",
        searchField: "1",
      },
      multipleSelection: [],
      tableData: [],
      loading: false,
      editOrderdialogFormVisible: false,
      editOrderformData: {
        id: "0",
        ordersn: "",
        settlement_time: "",
        settleTimeStr: "",
        settle_status: true,
      },
      checkEditOrderformRules: {},
      formLabelWidth: "120px",
      showTable: true,
      showLog: false,
      bonusListData: [],
      pvLogData: [],
      operateTitle: "",
      bitchFormData: {
        method: 1,
        settle_id: [],
        uname: "",
        subtype: "",
        isself: false,
      },
      batch_manage: false,
    };
  },
  methods: {
    handleClose(tag) {
      if (this.multipleSelection.length > 1) {
        this.$refs.dataTable.toggleRowSelection(tag, false);
      } else {
        this.$message.warning("请至少要保留一个操作项");
      }
    },
    statusManage(method = 1, row = {}) {
      this.bitchFormData.method = method;
      this.batch_manage = 1;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    getList(page = 0) {
      this.tableData = [];
      //强制刷新table视图 防止树形不更新
      this.showTable = false;
      setTimeout(() => {
        this.showTable = true;
      });
      if (page > 0) {
        this.formData.page = page;
      }
      this.loading = true;
      this.myfun.request("adminSettleGetSettleList.api", this.formData)
        .then((result) => {
          if (result.code == "1") {
            this.tableData = result.data.list;
          }
          this.formData.total = parseInt(result.data.total);
          this.loading = false;
        });
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.formData.pagesize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.formData.page = val;
      this.getList();
    },
    onSearch() {
      this.getList(1);
    },
    resetSettle(row) {
      //验证通过执行请求
      this.loading = true;
      this.myfun.request(
          "adminSettleResetSettle.api",
          {
            id: row.id,
          },
          "post"
        )
        .then((result) => {
          if (result.code == "1") {
            this.getList(1);
          }
        });
    },
    getHistorySettleList(tree, treeNode, resolve) {
      this.loading = true;
      this.myfun.request("adminSettleGetSettleList.api", tree)
        .then((result) => {
          if (result.code == "1") {
            resolve(result.data);
          }
          this.loading = false;
        });
    },
    getSettleBonusLog(id) {
      this.operateTitle = "奖金明细";
      this.loading = true;
      this.myfun.request("adminSettleGetSettleBonusLog.api", { id: id })
        .then((result) => {
          if (result.code == "1") {
            this.bonusListData = result.data;
            this.showLog = true;
          }
          this.loading = false;
        });
    },
    getSettlePvLog(id) {
      this.operateTitle = "业绩列表";
      this.loading = true;
      this.myfun.request("adminSettleGetSettlePvLog.api", { id: id })
        .then((result) => {
          if (result.code == "1") {
            this.pvLogData = result.data;
            this.showLog = true;
          }
          this.loading = false;
        });
    },
    settleAudit(row) {
      this.loading = true;
      this.myfun.request("adminSettleSettleAudit.api", { id: row.id })
        .then((result) => {
          if (result.code == "1") {
            this.getList(1);
          }
        });
    },

    settleGiveBonus(row) {
      this.loading = true;
      this.myfun.request("adminSettleSettleGiveBonus.api", { id: row.id })
        .then((result) => {
          if (result.code == "1") {
            this.getList(1);
          }
        });
    },

    doBatchManage() {
      if (this.bitchFormData.method === 1) {
        //批量复选
        if (this.multipleSelection.length < 1) {
          this.$message.warning("请选择要结算的周期");
        } else {
          let settle_id = [];
          this.multipleSelection.forEach((item, key) => {
            settle_id.push(item.id);
          });
          this.bitchFormData.settle_id = settle_id;
          //验证通过执行请求
          this.loading = true;
          this.myfun.request("adminSettleBatchAwards.api", this.bitchFormData)
            .then((result) => {
              if (result.code == "1") {
                this.getList();
                this.batch_manage = 0;
              } else {
                this.loading = false;
              }
            });
        }
      } else if (this.bitchFormData.method === 2) {
        if (this.bitchFormData.uname == "") {
          this.$message.warning("请填写会员编号");
          return;
        }
        if (this.bitchFormData.subtype == "") {
          this.$message.warning("请选择范围规则");
          return;
        }
        this.loading = true;
        this.myfun.request("adminMemberBitchMember.api", this.bitchFormData)
          .then((result) => {
            if (result.code == "1") {
              this.getList();
              this.batch_manage = 0;
            } else {
              this.loading = false;
            }
          });
      }
    },
    changeSettleStatus(value, id, index){
      //验证通过执行请求
      this.loading = true;
      this.myfun.request(
          "adminSettleEditSettleStatus.api",
          {
            id: id,
            statusIndex: index,
            status: value,
            isModifyMemberStatus: 1,
          },
          "post"
      )
          .then((result) => {


            if (result.code == "1") {
              this.$message({
                message: result.msg,
                type: "success",
              });
            } else {
              this.getList();
              this.$message({
                message: result.msg,
                type: "warning",
              });
            }
            this.loading = false;
          });
    }
  },
  created() {
    this.getList(1);
  },
  mounted() {},
  computed: {
    pageSizs() {
      return this.$store.state.pageSizes;
    },
  },
};
</script>

<style>
.mainwhite {
  background-color: #fff;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
}
.mainwhite-tiop {
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #f5f5f5;
  justify-content: flex-end;
}
.el-form-item {
  margin-bottom: 0;
}
.el-table .cell {
  overflow: inherit;
}

/* 禁用全选  // 找到表头那一行，然后把里面的复选框隐藏掉*/
.el-table__header-wrapper  .el-checkbox{
display:none
}
</style>
