<template>
  <div class="mainwhite">
    <div class="mainwhite-tiop">
      <el-form
          :inline="true"
          ref="formData"
          :model="formData"
          class="demo-form-inline"
      >
        <el-form-item style="margin-left: 0px; color: #bb0000; display: block">
          <span style="margin-right: 50px; font-size: 1rem">已结算: ¥ {{ bonus_statue200 }}</span>  <span style="margin-right: 50px; font-size: 1rem">待结算: ¥ {{ bonus_statue0 }}</span>
          <span style="margin-right: 50px; font-size: 1rem">已失效: ¥ {{ bonus_statuef1 }}</span>
        </el-form-item>
        <el-form-item>
          <el-select
              style="width: 120px"
              v-model="formData.bonus_type"
              placeholder="奖金类型"
          >
            <el-option label="全部" value="0">全部</el-option>
            <el-option :label="item.name" :value="item.type" v-for="(item, index) in bonusTypeList" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
              style="width: 120px"
              v-model="formData.status"
              placeholder="奖金状态"
          >
            <el-option label="全部" value="0">全部</el-option>
            <el-option label="已结算" value="200">已结算</el-option>
            <el-option label="待结算" value="1">待结算</el-option>
            <el-option label="已失效" value="-1">已失效</el-option>
          </el-select>
        </el-form-item>
        <el-form-item
                      prop="settlement_time_range"
        >
          <el-date-picker
            v-model="formData.settlement_time_range"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            align="right"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-input
              placeholder="请输入内容"
              v-model="formData.keywords"
              class="input-with-select"
          >
            <el-select
                style="width: 120px"
                v-model="formData.searchField"
                slot="prepend"
                placeholder="请选择"
            >
              <el-option label="手机号" value="2">手机号</el-option>
              <el-option label="会员ID" value="1">会员ID</el-option>
              <el-option label="订单编号" value="5">订单编号</el-option>
              <el-option label="昵称" value="3">昵称</el-option>
              <el-option label="姓名" value="4">姓名</el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch('formData')"
          >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <el-table
        v-loading="loading"
        :data="tableData"
        border
        stripe
        style="width: 100%; margin-top: 20px; margin-bottom: 20px; max-height: 700px; overflow: auto;"
    >
      <el-table-column prop="id" label="#ID" width="100"> </el-table-column>
      <el-table-column prop="user_info.uid" label="会员ID">
      </el-table-column>
      <el-table-column prop="user_info.phone" label="手机" >
      </el-table-column>
      <el-table-column prop="user_info.nickname" label="昵称" >
      </el-table-column>
      <el-table-column prop="bonusType" label="奖金类型" >
      </el-table-column>
      <el-table-column prop="bonus" label="奖金" >
      </el-table-column>
      <el-table-column prop="bv_order.store_order.order_id" label="关联订单" width="200">
        <template v-slot:default="scope">
          <span v-if="scope.row.oid > 0">
            <span v-if="scope.row.store_order != ''">
            {{scope.row.store_order.order_id}}
          </span>
          <span v-else-if="scope.row.bv_order != ''">
            {{scope.row.bv_order.store_order.order_id}}
          </span>
            <span v-else>
            -
          </span>
          <el-popover
              placement="top"
              width="160"
              v-model="scope.row.isDeductionBonusInterfaceAddress"
          >
            <p>扣除后本订单关联的所有奖金都将作废，确定扣除吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="scope.row.isDeductionBonusInterfaceAddress = false">取消</el-button>
              <el-button type="primary" size="mini" @click="() => {scope.row.isDeductionBonusInterfaceAddress = false;goDeductionOfBonus(scope.row.deductionBonusInterfaceAddress)}">确定</el-button>
            </div>
            <el-button slot="reference">扣奖</el-button>
          </el-popover>&nbsp;&nbsp;
          <el-popover
              placement="top"
              width="160"
              v-model="scope.row.isRestoreBonusInterfaceAddress"
          >
            <p>该操作会将已经扣除的奖金重新发放，确定操作吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="scope.row.isRestoreBonusInterfaceAddress = false">取消</el-button>
              <el-button type="primary" size="mini" @click="() => {scope.row.isRestoreBonusInterfaceAddress = false;goDeductionOfBonus(scope.row.restoreBonusInterfaceAddress)}">确定</el-button>
            </div>
            <el-button slot="reference">返奖</el-button>
          </el-popover>
          <el-popover
              placement="top"
              width="160"
              v-model="scope.row.isDeductionPvInterfaceAddress"
          >
            <p>该操作会将关联订单的所有业绩清除，确定操作吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="scope.row.isDeductionPvInterfaceAddress = false">取消</el-button>
              <el-button type="primary" size="mini" @click="() => {scope.row.isDeductionPvInterfaceAddress = false;goDeductionOfPv(scope.row.deductionPvInterfaceAddress)}">确定</el-button>
            </div>
            <el-button slot="reference">扣业绩</el-button>
          </el-popover>
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="status_str" label="状态" >
      </el-table-column>
      <el-table-column prop="created_at" label="结算时间" >
      </el-table-column>

    </el-table>
    <div class="block">
      <el-pagination
          style="margin-top: 1rem"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="formData.page"
          :page-sizes="pageSizs"
          :page-size="formData.pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="formData.total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "Bonus1List",
  components: {},
  data() {
    return {
      formData: {
        page: 1,
        pagesize: 10,
        total: 0,
        keywords: "",
        searchField: "1",
        isExport:0,
        status: '',
        bonus_type: '',
        settlement_time_range: []
      },
      tableData: [],
      loading: false,
      formLabelWidth: "120px",
      bonusTypeList: [],
      bonus_statue200: 0,
      bonus_statuef1: 0,
      bonus_statue0: 0,
    };
  },
  methods: {
    getList(page = 0) {
      this.tableData = [];
      if (page > 0) {
        this.formData.page = page;
      }
      this.loading = true;
      this.myfun.request("adminSettleGetBonusLog.api", this.formData)
          .then((result) => {
            if (result.code == "1") {
              this.tableData = result.data.list;
            }
            this.formData.total = parseInt(result.data.total);
            this.bonusTypeList = [];
            for(let i in result.data.bonusType){
              this.bonusTypeList.push({
                type: i,
                name: result.data.bonusType[i].name
              })
            }
            this.bonus_statue200 = result.data.bonus_statue200
            this.bonus_statuef1 = result.data.bonus_statuef1
            this.bonus_statue0 = result.data.bonus_statue0
            this.loading = false;
          });
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.formData.pagesize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.formData.page = val;
      this.getList();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleClose(tag) {
      if (this.multipleSelection.length > 1) {
        this.$refs.dataTable.toggleRowSelection(tag, false);
      } else {
        this.$message.warning("请至少要保留一个操作项");
      }
    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //验证通过执行请求
          this.loading = true;
          let apiName =
              this.editNetformData.type === 1
                  ? "adminMemberEditMemberRecommend.api"
                  : "adminMemberEditMemberParent.api";
          this.myfun.request(apiName, this.editNetformData)
              .then((result) => {
                if (result.code == "1") {
                  this.getList();
                  this.editNetdialogFormVisible = false;
                }
                this.loading = false;
              });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    onSearch() {
      this.getList(1);
    },
    goDeductionOfBonus(url) {
      window.open(url, '_blank')
    },
    goDeductionOfPv(url) {
      window.open(url, '_blank')
    }
  },
  created() {
    this.getList(1);
  },
  mounted() {},
  computed: {
    pageSizs() {
      return this.$store.state.pageSizes;
    },
  },
};
</script>

<style>
.huiyuanxinxi .el-form-item {
  margin: 0 0 15px 0;
}
.switshsdf {
  display: flex;
  flex-direction: column;
}
.mainwhite {
  background-color: #fff;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
}
.mainwhite-tiop {
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #f5f5f5;
  justify-content: flex-end;
}

.el-form-item {
  margin-bottom: 0;
}
</style>
