<template>
  <div>
    <el-form :inline="true" ref="formData" :model="formData" class="demo-form-inline" style="text-align: left">
      <el-form-item >
          <el-input  placeholder="请输入内容" v-model="formData.keywords" class="input-with-select">
            <el-select class="el-select" v-model="formData.searchField" slot="prepend" placeholder="请选择">
              <el-option label="商品名称" value="1">商品名称</el-option>
<!--              <el-option label="会员编号" value="2">会员编号</el-option>-->
<!--              <el-option label="手机号" value="3">手机号</el-option>-->
<!--              <el-option label="身份证号" value="3">身份证号</el-option>-->
            </el-select>
          </el-input>
      </el-form-item>
      <el-form-item >
          <el-select class="el-select" v-model="formData.merchantId"  placeholder="请选择">
            <el-option label="全部"  value="-1" />
            <el-option label="未设置供应商"  value="0" />
            <el-option :label="item.name" v-for="(item, index) in merchantList" :key="index" :value="item.id" />
          </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSearch('formData')">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table
        v-loading="loading"
        :data="tableData"
        stripe
        style="width: 100%" max-height="600">
      <el-table-column
          prop="id"
          label="#ID" width="50"
          >
      </el-table-column>
      <el-table-column
          prop="store_name"
          label="商品图" width="80"
      >
        <template slot-scope="scope">
          <el-image :src="scope.row.image"></el-image>
        </template>
      </el-table-column>
      <el-table-column
          prop="store_name"
          label="商品名称" width="200"
      >
      </el-table-column>
      <el-table-column prop="merchant_id" width="200">
        <template slot-scope="scope">
          <el-select style="width: 100%" class="el-select" v-model="scope.row.my_merchant_id" slot="prepend" placeholder="请选择" @change="setMerchat(scope.row)">
            <el-option label="未设置供应商"  value="0" />
            <el-option :label="item.name" v-for="(item, index) in merchantList" :key="index" :value="item.id" />
          </el-select>
        </template>
      </el-table-column>
      <el-table-column
          prop="status_str"
          label="属性"
          width="200"
          align="center"
          header-align="center"
      >
        <template slot-scope="scope">
          <el-switch
              :disabled="!myfun.checkAuthRule('adminGoodsEditGoodsStatus')"
              class="switch custom"
              v-model="scope.row.is_baodan"
              :active-value="'1'"
              :inactive-value="'0'"
              active-text="报单商品"
              inactive-text="非报单商品"
              active-color="#F56C6C"
              @change="
              (val) => {
                changeGoodsStatus(val, scope.row.id, 'is_baodan');
              }
            "
          ></el-switch>
          <el-switch
              :disabled="!myfun.checkAuthRule('adminGoodsEditGoodsStatus')"
              class="switch custom"
              v-model="scope.row.is_jifen"
              :active-value="'1'"
              :inactive-value="'0'"
              active-text="积分商品"
              inactive-text="非积分商品"
              active-color="#F56C6C"
              @change="
              (val) => {
                changeGoodsStatus(val, scope.row.id, 'is_jifen');
              }
            "
          ></el-switch>
          <!--          <el-switch-->
          <!--              :disabled="!myfun.checkAuthRule('adminMemberEditMember')"-->
          <!--              class="switch custom"-->
          <!--              v-model="scope.row.restricted_activation"-->
          <!--              :active-value="'1'"-->
          <!--              :inactive-value="'0'"-->
          <!--              active-text="死点"-->
          <!--              inactive-text="非死点"-->
          <!--              active-color="#F56C6C"-->
          <!--              @change="-->
          <!--              (val) => {-->
          <!--                changeMemberStatus(val, scope.row.id, 'restricted_activation');-->
          <!--              }-->
          <!--            "-->
          <!--          ></el-switch>-->
          <!--          <el-switch-->
          <!--              :disabled="!myfun.checkAuthRule('adminMemberEditMember')"-->
          <!--              class="switch custom"-->
          <!--              v-model="scope.row.restricted_settlement"-->
          <!--              :active-value="'1'"-->
          <!--              :inactive-value="'0'"-->
          <!--              active-text="退出"-->
          <!--              inactive-text="未退出"-->
          <!--              active-color="#F56C6C"-->
          <!--              @change="-->
          <!--              (val) => {-->
          <!--                changeMemberStatus(val, scope.row.id, 'restricted_settlement');-->
          <!--              }-->
          <!--            "-->
          <!--          ></el-switch>-->
        </template>
      </el-table-column>
      <el-table-column
          prop="status_str"
          label="火力值(BV)"

          align="center"
          header-align="center"
      >
        <template slot-scope="scope" >
          <template v-if="scope.row.is_baodan == '1'">
            <el-input v-model="scope.row.bv" @blur="changeGoodsStatus(scope.row.bv, scope.row.id, 'bv')"></el-input>
          </template>
          <template v-else>
            0
          </template>
        </template>

      </el-table-column>
      <el-table-column
          prop="status_str"
          label="积分抵扣比例(%)"

          align="center"
          header-align="center"
      >
        <template slot-scope="scope" >
          <template v-if="scope.row.is_jifen == '1'">
            <el-input v-model="scope.row.jifen_rate" @blur="changeGoodsStatus(scope.row.jifen_rate, scope.row.id, 'jifen_rate')"></el-input>
          </template>
          <template v-else>
            0
          </template>
        </template>

      </el-table-column>
      <el-table-column
          prop="price"
          label="商品价格" width="200"
      >
      </el-table-column>
      <el-table-column
          prop="stock"
          label="库存" width="100"
      >
      </el-table-column>

<!--      <el-table-column-->
<!--          fixed="right"-->
<!--          label="操作"-->
<!--          width="100">-->
<!--        <template slot-scope="scope">-->
<!--          <el-button @click="editOrder(scope.row)" type="text" size="small" v-if="myfun.checkAuthRule('adminOrderEditOrder')">编辑</el-button><br />-->
<!--&lt;!&ndash;          <el-button @click="editNet(scope.row, 1)" type="text" size="small" v-if="myfun.checkAuthRule('adminMemberEditMemberRecommend') ">改推荐人</el-button><br />&ndash;&gt;-->
<!--&lt;!&ndash;          <el-button @click="editNet(scope.row, 2)" type="text" size="small" v-if="myfun.checkAuthRule('adminMemberEditMemberParent') && scope.row.member_level === '3'">改安置人</el-button><br />&ndash;&gt;-->
<!--        </template>-->
<!--      </el-table-column>-->
    </el-table>
    <div class="block">
      <el-pagination style="margin-top: 1rem"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="formData.page"
          :page-sizes="pageSizs"
          :page-size="formData.pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="formData.total">
      </el-pagination>
    </div>




  </div>
</template>

<script>
  export default {
    name: 'GoodsList',
    components: {
    },
    data() {
      return {
        test: true,
        formData: {
          page: 1,
          pagesize: 10,
          total: 0,
          keywords: '',
          searchField: '1',
          merchantId: '-1'
        },
        tableData:[],
        loading: false,
        formLabelWidth: '120px',
        merchantList: []
      }
    },
    methods: {
      setMerchat(row){
        this.myfun.request('adminGoodsSetMerchant.api', row, 'post').then((result) => {
          if (result.code == '1') {
            this.$message({
              message: result.msg,
              type: 'success'
            });
          } else {
            this.$message({
              message: result.msg,
              type: 'warning'
            });
          }

          this.getList(1);

        });
      },
      getList(page = 0){
        this.tableData = [];
        if(page > 0){
          this.formData.page = page;
        }
        this.loading = true;
        this.myfun.request('adminGoodsGetGoodsList.api', this.formData, 'post').then((result) => {
          if (result.code == '1') {
            this.tableData = result.data.list;
            this.merchantList = result.data.merchantList
          } else {
            this.$message({
              message: result.msg,
              type: 'warning'
            });
          }
          this.formData.total = parseInt(result.data.total);
          this.loading = false;
        });
      },
      handleSizeChange(val) {
        // console.log(`每页 ${val} 条`);
        this.formData.pagesize = val;
        this.getList();
      },
      handleCurrentChange(val) {
        // console.log(`当前页: ${val}`);
        this.formData.page = val;
        this.getList();
      },
      onSearch(){
        this.getList(1);
      },
      changeGoodsStatus(value, id, index){
        //验证通过执行请求
        this.loading = true;
        this.myfun.request(
            "adminGoodsEditGoodsStatus.api",
            {
              id: id,
              statusIndex: index,
              status: value,
              isModifyMemberStatus: 1,
            },
            "post"
        )
            .then((result) => {


              if (result.code == "1") {
                this.$message({
                  message: result.msg,
                  type: "success",
                });
              } else {
                this.getList();
                this.$message({
                  message: result.msg,
                  type: "warning",
                });
              }
              this.loading = false;
            });
      }
    },
    created() {
      this.getList(1);
    },
    mounted() {
    },
    computed:{
      pageSizs(){
        return this.$store.state.pageSizes;
      }
    }
  }
</script>

<style>
  .el-select {
    width: 110px;
  }
  .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }


  /* switch按钮样式 */
  .switch.custom .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
  }
  /*打开时文字位置设置*/
  .switch.custom .el-switch__label--right {
    z-index: 1;
  }
  /* 调整打开时文字的显示位子 */
  .switch.custom .el-switch__label--right span{
    margin-left: 5px;
  }
  /*关闭时文字位置设置*/
  .switch.custom .el-switch__label--left {
    z-index: 1;
  }
  /* 调整关闭时文字的显示位子 */
  .switch.custom .el-switch__label--left span{
    margin-left: 20px;
  }
  /*显示文字*/
  .switch.custom .el-switch__label.is-active {
    display: block;
  }
  /* 调整按钮的宽度 */
  .switch.custom.el-switch .el-switch__core,
  .el-switch .el-switch__label {
    width: 100px !important;
    margin: 0;
  }
</style>
